<template>
    <section
        class="base-text-image"
        :class="[landscape ? 'base-text-image--landscape' : null,
                 padding ? 'base-text-image--padding' : null,
                 imagePosition ? 'base-text-image--image-left' : null]"
        :style="{
            '--background-color': backgroundColor,
            '--text-color': textColor
        }"
    >
        <div
            ref="section"
            class="base-text-image__wrapper"
        >
            <div class="base-text-image__content">
                <h2
                    v-if="!!$slots.title || pending"
                    class="base-text-image__title"
                >
                    <Skeletor v-if="pending" />
                    <slot
                        v-else
                        name="title"
                    />
                </h2>

                <div
                    v-if="!!$slots.description || pending"
                    class="base-text-image__description"
                >
                    <Skeletor v-if="pending" />
                    <slot
                        v-else
                        name="description"
                    />
                </div>

                <div
                    v-if="!!$slots.text || pending"
                    class="base-text-image__text"
                >
                    <Skeletor v-if="pending" />
                    <Skeletor v-if="pending" />
                    <Skeletor v-if="pending" />

                    <slot
                        v-else
                        name="text"
                    />
                </div>
            </div>

            <div
                v-if="image || pending"
                ref="imageElement"
                v-motion
                class="base-text-image__image"
            >
                <Skeletor
                    v-if="pending"
                    :shimmer="false"
                    height="100%"
                />
                <BaseImage
                    v-else-if="image"
                    :fade-in-view="true"
                    :image="image"
                    :width="image.width"
                    :height="image.height"
                    :sizes="imageSizes"
                />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ColorPalette } from '~/composables/useColorPalette';
import { useColorPalette } from '~/composables/useColorPalette';

import BaseImage from '~/components/BaseImage.vue';
import { Skeletor } from 'vue-skeletor';

import { useScrollAnimations } from '~/composables/useScrollAnimations';

defineOptions({
    name: 'BaseTextImage',
});

const { resolveColor, getTextColor } = useColorPalette();

interface Props {
    backgroundColor?: keyof ColorPalette;
    customBackgroundColor?: string;
    image?: {
        title: string,
        width: number,
        height: number
        [key: string]: any,
    };
    imagePosition?: boolean;
    pending?: boolean;
    landscape?: boolean;
    useParallax?: boolean;
    padding?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    backgroundColor: () => 'bright-orange',
    customBackgroundColor: undefined,
    pending: () => false,
    image: undefined,
    imagePosition: false,
    landscape: false,
    useParallax: false,
    padding: false
});

const backgroundColor = computed(() => {
    if (props.customBackgroundColor) {
        return props.customBackgroundColor;
    }
    else {
        return resolveColor(props.backgroundColor);
    }
});

const textColor = computed(() => getTextColor(props.backgroundColor));
const imageSizes = computed(() => {
    let desktopWidth = '660px';
    if (props.landscape) {
        desktopWidth = '929px';
    }
    return `(min-width: 992px) ${desktopWidth}, 100vw`;
});

const section = ref(null);
const imageElement = ref(null);

onMounted(() => {
    if (props.useParallax) {
        const { parallax } = useScrollAnimations();

        parallax(
            imageElement.value,
            {
                offset: .65,
                trigger: section.value
            });
    }
});
</script>

<style lang="less">
.base-text-image {
    background-color: var(--background-color);
    color: var(--text-color);
    overflow: hidden;

    @media @q-lg-min {
        padding-top: 10.25rem;
    }
}

.base-text-image__wrapper {
    .base-grid(var(--grid-maxWidth-page), 1);

    margin: 0 auto;
}

.base-text-image__content {
    grid-column: first/right-side-gutter;
    grid-row: 1;

    display: flex;
    padding: var(--spacing-2xl) 0 var(--spacing-md) 0;
    flex-direction: column;
    gap: var(--spacing-md);

    @media @q-lg-min {
        grid-column: first/right-gutter;

        padding: 0 0 10.25rem;
        gap: var(--spacing-lg);
        width: 61.5%;
    }
}

.base-text-image__image {
    grid-column: 1/-1;
    grid-row: 2;

    display: flex;
    align-self: stretch;
    width: 100%;
    max-height: 300px;

    figure {
        display: flex;
        flex: 0 0 100%;

        picture {
            display: flex;
            flex: 0 0 100%;
        }

        img {
            height: auto;
        }
    }

    @media @q-lg-min {
        grid-row: 1;
        justify-self: end;

        width: 50%;
        padding-left: var(--grid-gutter-margin);
        max-height: none;
    }
}

.base-text-image__description {
    .typography-text-sm();
}

.base-text-image__text {
    .typography-text();
}


.base-text-image--landscape {
    @media @q-lg-min {
        .base-text-image__content {
            grid-column: first;
            width: auto;
        }

        .base-text-image__image {
            grid-column: second/-1;
            width: auto;
        }
    }
}

.base-text-image--plain-link {
    .base-text-image__content a[href] {
        text-decoration: none;

        &:hover, &:active, &:focus-visible {
            text-decoration: underline;
        }
    }
}

.base-text-image--padding {
    padding-top: var(--spacing-2xs);

    .base-text-image__description {
        .typography-lead();
        text-wrap: auto;
    }

    .base-text-image__image {
        grid-column: first / right-side-gutter;
        max-height: 400px;
    }

    .base-arrow-link {
        .typography-label();
    }

    @media @q-lg-min {
        padding-top: 6rem;

        .base-text-image__content {
            grid-column: first / right-gutter;
        }

        .base-text-image__image {
            grid-column: 1 / -1;
            padding-right: var(--grid-gutter-margin);
            max-height: none;
        }
    }
}

.base-text-image--image-left {
    //.base-text-image__description {
    //    text-wrap: auto;
    //}

    @media @q-lg-min {
        .base-text-image__image {
            grid-column: 1 / -1;
            padding-right: 0;
            justify-self: start;
        }

        .base-text-image__content {
            grid-column: right-gutter / right-side-gutter;
            width: fit-content;
        }
    }
}
</style>
